<template>
  <el-row :gutter="12">
    <el-col :xs="20" :sm="3" class="mr-2"
      ><el-button
        type="primary"
        icon="el-icon-arrow-left"
        class="mr-1 mb-3"
        plain
        @click="$router.back()"
        >Back</el-button
      ></el-col
    >
    <el-col :xs="24" :sm="20">
      <el-card v-loading="loading">
        <h4 class="mt-3 mb-2">Order Details</h4>
        <div class="det">
          <div class="left">
            Order Number
          </div>
          <div class="right">{{ orderDetailInfo.order_no }}</div>
        </div>
        <div class="det">
          <div class="left">
            Order Status
          </div>
          <div class="right">
            {{
              orderDetailInfo.order_status == 0
                ? "Not Paid"
                : orderDetailInfo.order_status == 1
                ? "Paid"
                : orderDetailInfo.order_status == 2
                ? "Free"
                : orderDetailInfo.order_status == 3
                ? "Pending"
                : orderDetailInfo.order_status == 4
                ? "Refunding"
                : orderDetailInfo.order_status == 5
                ? "Refunded"
                : orderDetailInfo.order_status == 6
                ? "Cancel"
                : " "
            }}
          </div>
        </div>
        <div class="det">
          <div class="left">
            Time Zone
          </div>
          <div class="right">{{ orderDetailInfo.time_zone }}</div>
        </div>
        <div class="det">
          <div class="left">
            Order Created Time
          </div>
          <div class="right">{{ orderDetailInfo.created_at }}</div>
        </div>
        <div class="det">
          <div class="left">
            Allow Refund
          </div>
          <div class="right">
            {{ orderDetailInfo.is_refund == 0 ? "No" : "Yes" }}
          </div>
        </div>
        <div class="det">
          <div class="left">
            Order Source
          </div>
          <div class="right">
            {{
              orderDetailInfo.source == 0
                ? "Guest"
                : orderDetailInfo.source == 1
                ? "Member"
                : orderDetailInfo.source == 2
                ? "System"
                : "Probationary Member"
            }}
          </div>
        </div>

        <h4 class="mt-4 mb-2">Payment Information</h4>
        <div class="det">
          <div class="left">
            Payment Method
          </div>
          <div class="right">{{ paymentList[orderDetailInfo.pay_type] }}</div>
        </div>
        <div class="det">
          <div class="left">
            Time Of Payment
          </div>
          <div class="right">{{ orderDetailInfo.pay_time || "-" }}</div>
        </div>
        <div class="det">
          <div class="left">
            Currency
          </div>
          <div class="right">
            {{ orderDetailInfo.currency == 0 ? "USD" : "EUR" }}
          </div>
        </div>
        <div class="det">
          <div class="left">
            Total Amount
          </div>
          <div class="right">
            {{ orderDetailInfo.currency == 0 ? "$" : "€"
            }}{{ orderDetailInfo.order_amount }}
          </div>
        </div>
        <!-- <div class="det">
          <div class="left">
            Fee
          </div>
          <div class="right">
            {{ orderDetailInfo.currency == 0 ? "$" : "€"
            }}{{ orderDetailInfo.fee }}
          </div>
        </div> -->
        <div class="det">
          <div class="left">
            Payment Amount
          </div>
          <div class="right">
            {{ orderDetailInfo.currency == 0 ? "$" : "€"
            }}{{ orderDetailInfo.pay_amount }}
          </div>
        </div>
        <div class="det" v-if="[3, 4].includes(orderDetailInfo.pay_type)">
          <div class="left">
            Order Number
          </div>
          <div class="right">{{ orderDetailInfo.order_number }}</div>
        </div>
        <div class="det" v-if="[3, 4].includes(orderDetailInfo.pay_type)">
          <div class="left">
            Upload Payment
          </div>
          <div class="right">
            <el-image
              style="height: 60px"
              fit="cover"
              :src="orderDetailInfo.offline_certificate"
              :preview-src-list="srcList"
            >
            </el-image>
          </div>
        </div>
      </el-card>
      <el-card class="mt-2">
        <div class="d-flex justify-content-between">
          <h4>Event Information</h4>
          <el-button type="primary" size="small" @click="getCheckList(orderDetailInfo)">Check Program</el-button>
        </div>
        <div class="eventInfo row">
          <div class="col-3">
            <el-image :src="orderDetailInfo.image" fit="cover" class="eventImage">
              <div slot="error" class="image-slot">
                <img :src="errorImage" class="errorpng" alt="" />
              </div>
            </el-image>
          </div>
          <div class="pl-5 col-9">
            <div class="det">
              <div class="left">
                Event Name
              </div>
              <div class="right">{{ orderDetailInfo.title }}</div>
            </div>
            <div class="det">
              <div class="left">
                Type
              </div>
              <div class="right">{{ orderDetailInfo.order_type == 1 ? "Package" : "Single" }}</div>
            </div>
            <div class="det">
              <div class="left">
                Package Name
              </div>
              <div class="right">{{ orderDetailInfo.setmeal_title ? orderDetailInfo.setmeal_title : "-" }}</div>
            </div>
            <div class="det">
              <div class="left">
                Is Early Bird
              </div>
              <div class="right">{{ orderDetailInfo.is_use_bird_price == 0 ? "No" : "Yes" }}</div>
            </div>
            <div class="det">
              <div class="left">
                Unit Price
              </div>
              <div class="right">{{ orderDetailInfo.currency == 0 ? "$" : "€" }}{{orderDetailInfo.unit_price}}</div>
            </div>
            <div class="det">
              <div class="left">
                Quantity
              </div>
              <div class="right">{{ orderDetailInfo.number }}</div>
            </div>
            <div class="det">
              <div class="left">
                Total Amount
              </div>
              <div class="right">{{ orderDetailInfo.currency == 0 ? "$" : "€" }}{{ orderDetailInfo.total_price }}</div>
            </div>
          </div>
        </div>
        <!-- <el-table :data="tableData" style="width: 100%;">
          <el-table-column
            prop="title"
            align="center"
            show-overflow-tooltip
            label="Event Name"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="order_type"
            show-overflow-tooltip
            align="center"
            label="Type"
            min-width="100"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.order_type == 1 ? "Package" : "Single"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            show-overflow-tooltip
            label="Package Name"
            min-width="150"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.setmeal_title ? scope.row.setmeal_title : "-"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="is_use_bird_price"
            show-overflow-tooltip
            align="center"
            label="Is Early Bird"
            min-width="150"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.is_use_bird_price == 0 ? "No" : "Yes" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit_price"
            align="center"
            show-overflow-tooltip
            label="Unit Price"
            min-width="100"
          >
            <template slot-scope="scope">
              <span
                >{{ scope.row.currency == 0 ? "$" : "€"
                }}{{ scope.row.unit_price }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="number"
            align="center"
            show-overflow-tooltip
            label="Quantity"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="total_price"
            label="Total Amount"
            show-overflow-tooltip
            align="center"
            min-width="120"
          >
            <template slot-scope="scope">
              <span
                >{{ scope.row.currency == 0 ? "$" : "€"
                }}{{ scope.row.total_price }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="Action"
            align="center"
            width="70"
            fixed="right"
          >
            <template slot-scope="scope">
              <span
                ><i @click="getCheckList(scope.row)" class="el-icon-view"></i
              ></span>
            </template>
          </el-table-column>
        </el-table> -->
      </el-card>
      <el-card class="mt-2">
        <div class="d-flex justify-content-between align-items-center">
          <h4>Assigned User List</h4>
          <div v-if="[1, 2].includes(orderDetailInfo.order_status)">
            <el-button
              v-if="orderDetailInfo.gift_count == orderDetailInfo.number"
              disabled
              type="info"
              >The Number Of People Is Full</el-button
            >
            <el-button type="primary" v-else @click="addUser()" plain>
              +Add New</el-button
            >
          </div>
        </div>
        <el-table :data="orderDetailInfo.gift_log" style="width: 100%">
          <el-table-column prop="gift_id" align="center" width="50" label="ID">
          </el-table-column>
          <el-table-column
            prop="real_name"
            show-overflow-tooltip
            align="center"
            label="User"
          >
          </el-table-column>
          <el-table-column
            prop="email"
            show-overflow-tooltip
            align="center"
            label="Email"
          >
          </el-table-column>
          <el-table-column
            prop="add_time"
            show-overflow-tooltip
            align="center"
            label="Time"
          >
          </el-table-column>
          <el-table-column label="Action" align="center" width="80">
            <template slot-scope="scope">
              <span
                ><i
                  @click="eventDeleteGiftUsers(scope.row.gift_id)"
                  class="el-icon-delete"
                ></i
              ></span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card class="mt-2">
        <h4>Order History List</h4>
        <el-table :data="logList" style="width: 100%">
          <el-table-column
            prop="operator_type"
            align="center"
            width="120"
            label="Type"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.operator_type == 1 ? "System" : "Client"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="170"
            show-overflow-tooltip
            prop="created_at"
            align="center"
            label="Time"
          >
          </el-table-column>
          <el-table-column
            prop="order_status"
            align="center"
            show-overflow-tooltip
            width="130"
            label="Order status"
          >
            <template slot-scope="scope">
              <span
                >{{
                  scope.row.order_status == 0
                    ? "Not Paid"
                    : scope.row.order_status == 1
                    ? "Paid"
                    : scope.row.order_status == 2
                    ? "Free"
                    : scope.row.order_status == 3
                    ? "Pending"
                    : scope.row.order_status == 4
                    ? "Refunding"
                    : scope.row.order_status == 5
                    ? "Refunded"
                    : scope.row.order_status == 6
                    ? "Cancel"
                    : "-"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="action_note"
            align="center"
            show-overflow-tooltip
            label="Remark"
          >
          </el-table-column>
        </el-table>
        <div style="text-align: right; margin-top: 30px; margin-bottom: 10px">
          <el-pagination
            :current-page="page"
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            @current-change="handleChangePage"
            hide-on-single-page
          ></el-pagination>
        </div>
      </el-card>
      <el-card class="mt-2">
        <h4>Operation</h4>
        <div class="pt-4">
          <el-button
            @click="orderCheck(1)"
            v-if="[0].includes(orderDetailInfo.order_status)"
            type="primary"
            >Paid</el-button
          >
          <el-button
            @click="orderCheck(2)"
            v-if="[0, 3].includes(orderDetailInfo.order_status)"
            type="primary"
            >Cancel</el-button
          >
          <el-button
            @click="orderCheck(3)"
            v-if="
              [1].includes(orderDetailInfo.order_status) &&
                orderDetailInfo.is_refund == 1 &&
                [2, 3, 4, 5].includes(orderDetailInfo.pay_type)
            "
            type="primary"
            >Request Refund</el-button
          >
          <el-button @click="openNotes()" type="primary">Remark</el-button>
        </div>
      </el-card>
    </el-col>
    <el-dialog title="All Program" width="30%" :visible.sync="checkEvent">
      <div
        style="border-bottom:1px solid #f1f1f1;color:#666; padding:10px 0"
        v-for="(item, index) of checkList"
        :key="index"
      >
        {{ item.program_title }}
      </div>
    </el-dialog>
    <el-dialog title="Add New" width="40%" :visible.sync="dialogUser">
      <div class="flex">
        <el-select
          v-model="member"
          style="width: 100%"
          filterable
          placeholder="Please Select"
          :filter-method="filterFriends"
          clearable
          popper-class="optionsContent"
          value-key="user_id"
          @change="chooseMember"
        >
          <el-option
            v-for="item in userList"
            :key="item.user_id"
            :label="item.real_name"
            :value="item"
          >
            <el-table
              :data="[item]"
              style="width: 100%"
              :show-header="false"
              tooltip-effect="light"
            >
              <el-table-column prop="date" width="60">
                <template slot-scope="scope">
                  <div class="flex justify-end">
                    <el-avatar
                      icon="el-icon-user-solid"
                      :src="scope.row.avatar"
                      :size="30"
                    >
                    </el-avatar>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="real_name" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="email" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="organization_name" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </el-option>
        </el-select>
      </div>
      <div class="vx-row flex items-center" v-if="tagsList.length > 0">
        <div class="vx-col sm:w-12/12 w-full">
          <el-tag
            v-for="(tag, i) in tagsList"
            :key="tag.user_id"
            closable
            @close="delTag(i)"
            type="info"
            class="mr-3 mt-3"
          >
            {{ tag.real_name }}
          </el-tag>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :disabled="tagsList.length == 0"
          @click="eventGiftUsers"
          >Submit</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="Notes" width="40%" :visible.sync="dialogNotes">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Please input"
        v-model="notes"
      >
      </el-input>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="!notes" @click="orderRemark()"
          >Submit</el-button
        >
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      errorImage: require("@/assets/images/public/fail.png"),
      tableData: [],
      orderDetailInfo: {},
      logList: [],
      pageSize: 10,
      total: 0,
      page: 1,
      srcList: [],
      paymentList: [
        "Not Sure",
        "Free",
        "PayPal",
        "Offline[personal]",
        "Offline[group]",
        "Credit card payment",
        "System"
      ],
      checkList: [],
      checkEvent: false,

      dialogUser: false,
      userList: [],
      member: "",
      tagsList: [],

      notes: "",
      dialogNotes: false,
      loading: false
    };
  },

  computed: {},

  created() {},
  mounted() {
    this.eventOrderDetail();
    this.getOrderLogList();
  },
  methods: {
    openNotes() {
      this.notes = "";
      this.dialogNotes = true;
    },
    orderRemark() {
      this.$http
        .orderRemark({
          id: this.$route.query.id,
          note: this.notes
        })
        .then(res => {
          if (res.status == 200) {
            this.dialogNotes = false;
            this.notes = "";
            this.getOrderLogList();
          }
        });
    },
    addUser() {
      this.member = "";
      this.tagsList = [];
      this.dialogUser = true;
      this.searchUserList();
    },
    getCheckList(item) {
      this.$http
        .getEventProgramList({
          event_id: item.event_id,
          order_type: item.order_type,
          order_data: item.order_data
        })
        .then(res => {
          if (res.status == 200) {
            this.checkList = res.data;
            this.checkEvent = true;
          }
        });
    },
    orderCheck(type) {
      if (type == 1) {
        this.$router.push({
          path: "/PayMoney",
          query: {
            model_type: 2,
            id: this.orderDetailInfo.id,
            model_id: this.orderDetailInfo.event_id,
            package_type: this.orderDetailInfo.package_type
          }
        });
      } else if (type == 2) {
        this.$confirm("Are you sure you want to cancel?", "Tips", {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        })
          .then(() => {
            this.$http
              .eventOrderCancel({ id: this.orderDetailInfo.id })
              .then(res => {
                if (res.status == 200) {
                  this.getOrderLogList();
                  this.eventOrderDetail();
                }
              });
          })
          .catch(() => {});
      } else if (type == 3) {
        this.$confirm("Are you sure you want to apply for a refund?", "Tips", {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        })
          .then(() => {
            this.$http
              .eventRefundApply({ id: this.orderDetailInfo.id })
              .then(res => {
                if (res.status == 200) {
                  this.getOrderLogList();
                  this.eventOrderDetail();
                }
              });
          })
          .catch(() => {});
      }
    },
    searchUserList(val) {
      this.$http
        .searchUserList({
          keyword: val ? val : ""
        })
        .then(res => {
          this.userList = res.data;
        });
    },
    chooseMember(item) {
      let idArray = this.tagsList.map(ele => {
        return ele.user_id;
      });
      if (
        this.tagsList.length >=
        this.orderDetailInfo.number - this.orderDetailInfo.gift_count
      ) {
        this.$message.warning("The number of donors exceeds!");
        return;
      }
      if (idArray.includes(item.user_id)) return;
      if (item) {
        this.tagsList.push(item);
        this.member = "";
        this.searchUserList();
      }
    },
    filterFriends(val) {
      clearTimeout(this.timeId);
      this.timeId = setTimeout(() => {
        this.searchUserList(val);
      }, 800);
    },
    eventDeleteGiftUsers(gift_id) {
      this.$confirm(
        "This operation will delete the record. Are you sure to continue?",
        "Tips",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.$http.eventDeleteGiftUsers({ gift_id }).then(res => {
            if (res.status == 200) {
              this.getOrderLogList();
              this.eventOrderDetail();
            }
          });
        })
        .catch(() => {});
    },
    eventGiftUsers() {
      let idList = [];
      this.tagsList.forEach(item => {
        idList.push(item.user_id);
      });
      this.$http
        .eventGiftUsers({ id: this.$route.query.id, user_id: idList })
        .then(res => {
          if (res.status == 200) {
            this.eventOrderDetail();
            this.getOrderLogList();
            this.dialogUser = false;
          }
        });
    },
    handleChangePage(num) {
      this.page = num;
      this.getOrderLogList();
    },
    getOrderLogList() {
      this.$http
        .getOrderLogList({
          id: this.$route.query.id,
          per_page: this.pageSize,
          page: this.page
        })
        .then(res => {
          if (res.status == 200) {
            this.logList = res.data.data;
            this.total = res.data.total;
          }
        });
    },
    eventOrderDetail() {
      this.loading = true;
      this.tableData = [];
      this.srcList = [];
      this.$http.eventOrderDetail({ id: this.$route.query.id }).then(res => {
        this.loading = false;
        if (res.status == 200) {
          this.orderDetailInfo = res.data;
          this.tableData.push(res.data);
          this.srcList.push(res.data.offline_certificate);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.optionsContent .el-select-dropdown__item {
  height: 55px !important;
  padding: 0 !important;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #e5e5e5;
}
::v-deep .el-dialog__body {
  padding: 15px;
}
.det {
  display: flex;
  .left {
    width: 260px;
    color: #333333;
    padding: 5px 0;
  }
  .right {
    flex: 1;
    color: #999999;
    padding: 5px 0;
  }
}
.eventInfo {
  padding: 5px 0;
  .left, .right{
    padding: 0 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .left {
    width: 200px;
  }
  .eventImage {
    width: 200px;
    height: 200px;
    background: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    .errorpng {
      width: 38px;
      height: 31px;
    }
  }
}
</style>
